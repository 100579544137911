import './App.css';

import HomeComponent from './components/homeComponent';

function App() {
  return (
    <div className="App"> 
      <HomeComponent></HomeComponent>
    </div>
  );
} 

export default App;